/* eslint-disable import/prefer-default-export */
import { apolloClient } from '../vue-apollo';
import store from '../store';
import i18n from '../plugins/i18n';
import GET_SIGNED_UPLOAD_URL from '../graphql/Query/GetSignedUploadUrl.gql';
import GET_FILE_ID from '../graphql/Query/GetFileId.gql';
import UNLOCK_FILE from '../graphql/Mutation/UnlockFile.gql';
import { timeout } from './async';

const getUploadUrl = async ({ file, memberId, tags, progressId, origin }) => {
  const query = await apolloClient.query({
    query: GET_SIGNED_UPLOAD_URL,
    variables: {
      expiry: store.getters.toTimezone().add(5, 'minutes').unix(),
      fileName: file.name,
      displayName: file.name,
      memberId,
      metaData: [
        { key: 'progressId', valueString: progressId },
        ...(origin ? [{ key: 'origin', valueString: origin }] : []),
      ],
      mimetype: file.type,
      providerId: store.getters.getProviderId(),
      tags,
    },
    fetchPolicy: 'no-cache',
  });

  return query?.data?.getSignedUploadUrl;
};

const getFileId = async (progressId, attempt = 0) => {
  if (attempt >= 10) throw new Error(i18n.t('patientPortal.files.upload.unlockError'));
  try {
    const { data } = await apolloClient.query({
      query: GET_FILE_ID,
      variables: {
        providerId: store.getters.getProviderId(),
        fileId: progressId,
      },
    });
    const { id } = data.getFile;
    return id;
  } catch {
    await timeout(500);
    return getFileId(progressId, attempt + 1);
  }
};

const unlockFile = async (fileId) => {
  try {
    await apolloClient.mutate({
      mutation: UNLOCK_FILE,
      variables: {
        providerId: store.getters.getProviderId(),
        fileId,
        emitDomainEvent: false,
      },
    });
  } catch {
    throw new Error(i18n.t('patientPortal.files.upload.unlockError'));
  }
};

export const uploadPatientFile = async ({ file, memberId, tags, unlock = true, origin }) => {
  const progressId = `${Math.round(Math.random() * 1000000 + 1)}`;
  const url = await getUploadUrl({
    file,
    memberId,
    origin,
    progressId,
    tags,
  });

  const headers = new Headers();
  headers.append('x-goog-resumable', 'start');
  const requestOptions = {
    method: 'POST',
    body: file,
    redirect: 'follow',
    mode: 'no-cors',
    headers,
  };
  await fetch(url, requestOptions);

  if (!unlock) return;
  const fileId = await getFileId(progressId);
  await unlockFile(fileId);
};
