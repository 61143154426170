<template>
  <register-container centerContent>
    <v-row>
      <!-- Upload Dialog -->
      <v-col cols="12" class="d-flex justify-center">
        <v-card class="pb-2 file-upload-card" :elevation="$vuetify.breakpoint.smAndDown ? 0 : 2">
          <v-card-title class="h6-text">
            {{ pageTitle || $t('registration.registerFileUpload.new') }}
          </v-card-title>
          <v-card-subtitle>{{ $t('registration.registerFileUpload.subtitle') }}</v-card-subtitle>
          <v-card-text>
            <div
              :class="{
                uploader: true,
                'uploader--drop': isDropping,
              }"
              class="pa-sm-16 pa-4"
              @drop.prevent="addFile"
              @dragover.prevent="isDropping = true"
            >
              <v-icon class="uploader-icon">mdi-file-document-multiple-outline</v-icon>
              <h2>
                {{
                  this.$vuetify.breakpoint.mdAndUp
                    ? $t('registration.registerFileUpload.dragDrop')
                    : $t('registration.registerFileUpload.uploadFile')
                }}
              </h2>
              <br />
              <span v-if="this.$vuetify.breakpoint.mdAndUp">
                <small> {{ $t('registration.registerFileUpload.or') }} </small>
              </span>

              <div class="input-container">
                <v-file-input
                  v-model="file"
                  label="file.pdf"
                  outlined
                  dense
                  :disabled="filesToUpload.length >= maxFiles"
                  :error-messages="filesError"
                  accept=".pdf,.png,.jpeg,.jpg,.bmp,.gif"
                  ref="uploader"
                ></v-file-input>
                <v-btn color="primary" depressed class="choose-button" @click="onButtonClick">
                  <span v-if="this.$vuetify.breakpoint.mdAndUp"
                    >{{ $t('registration.registerFileUpload.choose') }}
                  </span>
                  <v-icon v-else>mdi-dots-horizontal</v-icon>
                </v-btn>
              </div>
            </div>
            <div class="pt-4">
              {{
                $t('registration.registerFileUpload.acceptedTypes', {
                  types: acceptedFileTypes.join(', '),
                })
              }}
            </div>
          </v-card-text>
          <div v-if="filesToUpload.length">
            <v-divider />
            <v-card-title>{{ $t('registration.registerFileUpload.fileUploadTitle') }}</v-card-title>
            <v-card-subtitle>{{
              $t('registration.registerFileUpload.fileUploadSubtitle')
            }}</v-card-subtitle>
            <v-card-text>
              <v-list-item v-for="file in filesToUpload" :key="file.name"
                ><v-list-item-icon>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="error" v-bind="attrs" v-on="on" @click="deleteItem(file)"
                        >mdi-trash-can-outline</v-icon
                      >
                    </template>
                    <span> Delete </span>
                  </v-tooltip> </v-list-item-icon
                >{{ file.name }}</v-list-item
              >
            </v-card-text>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        class="d-flex justify-center align-center"
        :class="this.$vuetify.breakpoint.mdAndUp ? 'mt-0' : 'mt-4'"
      >
        <v-select
          v-if="leadLocations.length"
          v-model="leadLocation"
          :items="leadLocations"
          item-text="name"
          item-value="id"
          outlined
          :placeholder="$t('prompt.select')"
          :label="$t('main.location')"
          class="lead-location-select"
        ></v-select>
      </v-col>
    </v-row>

    <loading-dialog
      v-model="dialog"
      :isButtonDisabled="isModalButtonDisabled"
      @complete="onComplete"
    >
      <template slot="title">{{ modalTitle }}</template>
      <template slot="subtitle"> {{ modalSubtitle }}</template>
      <template slot="button">
        <span v-if="(willUploadFiles && uploadingComplete) || !willUploadFiles">{{
          $t('main.continue')
        }}</span>
        <v-progress-circular indeterminate v-else></v-progress-circular>
      </template>
    </loading-dialog>
  </register-container>
</template>
<style lang="scss">
.choose-button {
  z-index: 2;
  cursor: pointer;
}
.file-upload-card {
  width: 600px !important;
}
.input-container {
  position: relative;
  width: 100%;
}
.input-container:hover {
  cursor: pointer !important;
}

.input-container .v-btn {
  position: absolute;
  top: 2px;
  right: 2px;
}

.input-container .v-input {
  cursor: pointer !important;
  position: relative;
  z-index: 1;
}

.input-container .v-file-input__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.input-container .v-input__append-inner {
  display: none;
  margin-right: 135px;
}
.lead-location-select {
  max-width: 600px;
}
.uploader {
  border: 1px dashed var(--v-accent-base);
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 1;
}
.uploader > .v-icon {
  font-size: 4rem;
  margin-bottom: 1rem;
}
.uploader--drop {
  color: var(--v-primary-base) !important;
  border-color: var(--v-primary-base);
  opacity: 0.6;
}
.uploader--drop > .v-icon {
  color: var(--v-primary-base) !important;
}

@media all and (max-width: 600px) {
  .file-upload-card {
    width: 100vw !important;
    .v-card__title {
      word-break: keep-all;
    }
  }
}
@media all and (max-width: 768px) {
  .file-upload-card {
    background-color: unset !important;
  }
}
</style>
<script>
import to from 'await-to-js';
import RegisterContainer from '../components/RegisterContainer.vue';
import LoadingDialog from '../components/LoadingDialog.vue';
import UPDATE_USER from '../graphql/Mutation/UpdateUser.gql';
import { MEMBER } from '../constants/roles';
import { LEAD_LOCATION, LEAD_SOURCE } from '../constants/customFields';

import { uploadPatientFile } from '../utils/file-upload';

export default {
  name: 'RegisterFileUpload',
  components: { RegisterContainer, LoadingDialog },
  data() {
    return {
      acceptedFileTypes: ['.pdf', '.png', '.jpeg', '.jpg', '.bmp', '.gif'],
      customModalSubtitle: null,
      dialog: false,
      isEndOfRegistration: false,
      file: null,
      memberFiles: [],
      filesToUpload: [],
      fileUploadErrors: [],
      isDropping: false,
      leadLocation: null,
      leadLocations: [],
      loading: false,
      maxFiles: 5,
      uploadingComplete: false,
    };
  },
  computed: {
    config() {
      return this.$store.getters.getConfig(this.$options.name);
    },
    completed() {
      return !this.leadLocations?.length || !!this.leadLocation;
    },
    fileExtension() {
      return this.file?.name.match(/\.[0-9a-z]+$/i)[0];
    },
    filesError() {
      const errors = [];
      if (this.filesToUpload.length === this.maxFiles) {
        errors.push(this.$t('registration.registerFileUpload.maxFiles'));
      }
      if (
        !!this.fileExtension &&
        !this.acceptedFileTypes.includes(this.fileExtension.toLowerCase())
      ) {
        errors.push(
          this.$t('registration.registerFileUpload.fileTypeError', { type: this.fileExtension })
        );
      }
      return errors;
    },
    isModalButtonDisabled() {
      if (this.willUploadFiles) {
        return !this.uploadingComplete;
      }
      return false;
    },
    modalSubtitle() {
      if (this.customModalSubtitle) return this.customModalSubtitle;
      if (this.isEndOfRegistration) {
        if (this.willUploadFiles) {
          return this.uploadingComplete
            ? this.$t('registration.registerFileUpload.registerThankyou')
            : this.$t('registration.registerFileUpload.uploadingInSubtitleProgress');
        }
        return this.$t('registration.registerFileUpload.registerThankyou');
      }
      if (this.willUploadFiles) {
        return this.uploadingComplete
          ? this.$t('registration.registerFileUpload.uploadingComplete')
          : this.$t('registration.registerFileUpload.uploadingInProgress');
      }
      return '';
    },
    modalTitle() {
      if (this.customModalSubtitle && !this.willUploadFiles)
        return this.$t('registration.registerFileUpload.registerComplete');
      if (this.isEndOfRegistration) {
        if (this.willUploadFiles) {
          return this.uploadingComplete
            ? this.$t('registration.registerFileUpload.uploadingComplete')
            : this.$t('registration.registerFileUpload.uploadingInProgress');
        }
        return this.$t('registration.registerFileUpload.registerComplete');
      }
      if (this.willUploadFiles) {
        return this.uploadingComplete
          ? this.$t('registration.registerFileUpload.uploadingComplete')
          : this.$t('registration.registerFileUpload.uploadingInProgress');
      }
      return '';
    },
    willUploadFiles() {
      return !!this.filesToUpload.length;
    },
  },
  methods: {
    addFile(e) {
      if (this.filesToUpload.length >= this.maxFiles) return;
      this.isDropping = false;
      const droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      const [file] = droppedFiles;
      this.file = file;
    },
    deleteItem(file) {
      const fileToDeleteIndex = this.filesToUpload.map((f) => f.name).indexOf(file.name);
      this.filesToUpload.splice(fileToDeleteIndex, 1);
    },
    getInitialState() {
      if (!this.config) return;
      Object.keys(this.config).forEach((option) => {
        this[option] = this.config[option];
      });
    },
    onButtonClick() {
      this.$refs.uploader.$refs.input.click();
    },
    onComplete() {
      /*
        TODO: eliminate isPageFlowV2 check once we have
        fully migrated over to pageflow 2.0 system
      */
      if (this.isPageflowV2) {
        this.$emit('next');
      } else {
        this.$router.push({ name: this.$store.getters.nextFlowRoute(this.$route.name) });
      }
    },
    async onNextClick() {
      if (this.leadLocations.length) this.addLeadDataToUser();
      if (this.willUploadFiles || this.isEndOfRegistration) {
        this.dialog = true;
      }

      if (this.willUploadFiles) {
        const { memberId } = this.$store.state.registration;
        const tags = this.results ? ['Result'] : [];
        this.$store.commit('setCompleted', false);
        const promises = this.filesToUpload.map(
          (file) =>
            new Promise((resolve) => {
              uploadPatientFile({ file, memberId, tags }).then(() => {
                resolve();
              });
            })
        );
        Promise.all(promises);
        this.uploadingComplete = true;
      }
      if (!this.isEndOfRegistration) {
        this.onComplete();
      }
    },
    async addLeadDataToUser() {
      const { providerId, id: userId } = this.$store.state.user.info;

      const [errors] = await to(
        this.$apollo.mutate({
          mutation: UPDATE_USER,
          variables: {
            providerId,
            userId,
            type: MEMBER,
            updates: {
              customFields: [
                {
                  field: LEAD_LOCATION,
                  value: this.leadLocation,
                },
                {
                  field: LEAD_SOURCE,
                  value: 'casel',
                },
              ],
            },
            customFieldOne: LEAD_LOCATION,
            customFieldTwo: LEAD_SOURCE,
            addCustomFields: true,
          },
        })
      );
      if (errors) {
        console.log(errors);
      }
    },
  },
  mounted() {
    this.$store.commit('setCompleted', this.completed);
    this.$store.commit('setOnNextClick', this.onNextClick);
    if (!this.isPageflowV2) this.getInitialState();
  },
  watch: {
    file(val) {
      if (val) {
        const extension = val.name.match(/\.[0-9a-z]+$/i)[0];
        if (this.acceptedFileTypes.includes(extension.toLowerCase())) {
          this.filesToUpload.push(val);
        }
      }
    },
    completed(val) {
      this.$store.commit('setCompleted', val);
    },
    config(val) {
      if (val) {
        if (!this.isPageflowV2) this.getInitialState();
      }
    },
  },
};
</script>
